<template>
    <div class="mb-2 ">
                <v-card>
                    <v-card-text>
                        Andreas Marty (Arth), Präsident<br>
                        Dr. Ruedi Beeler (Rothenthurm)<br>
                        Maria Bürgler (Illgau)<br>
                        Marlis Birchler (Einsiedeln)<br>
                        Peter Studiger (Wollerau)<br>
                        Anton Schnellmann (Galgenen)<br>
                        Urs Peter Seeholzer (Küssnacht).
                    </v-card-text>
                </v-card>

    </div>
</template>

<script>
    export default {
        name: "Kantonskirchenrat_Vorberatenden_Kommission_Gesetzte"
    }
</script>

<style scoped>

</style>